import { getDateStr, isMobile, thousand,getPhone,getBrowser } from '@/libs/utils'
import { removeAuthData, setBoardCookie, setLangCookie, tokenCheck } from '@/libs/auth-helper'
import { mapGetters, mapState } from 'vuex'
import { CASH_STATUS, CASH_STATUS_MOBILE, NonIframeGameCode } from '@/libs/constants'
import { cashList } from '@/api/cash'
import { bettingList, bettingDetail } from '@/api/betting'
import { fetchGameUrl, getGame } from '@/api/game'
import Alert from '@/components/common/Alert'
import AlertMobile from '@/components/common/mobile/AlertMobile'
import { getBoardDetail, getBoardList, saveBoard, saveReply } from '@/api/board'
import { format } from 'date-fns'
import { signOut } from '@/api/member'
import store from '@/store'
import { resetRunMode } from '../libs/auth-helper'
import { ApiFailError } from '../libs/customError'
export default {
  computed: {
    ...mapState([
      'userData',
      'commonCode',
      'commonCodeByCode',
      'coinAmt',
      'categoryCode',
      'categoryCodeByCode',
      'productList'
    ]),
    ...mapGetters([
      'commonCode'
    ]),
    casinoList() {return this.productList.filter(item => item.groupCode === 'casino')},
    slotList() {return this.productList.filter(item => item.groupCode === 'slot')},
    minigameList() {return this.productList.filter(item => item.groupCode === 'minigame')},
    sportsList() {return this.productList.filter(item => item.groupCode === 'sports')}
  },
  data() {
    return {
      allChecked: false,
      pageInfo: {
        page: 1,
        count_per_list: 10,
        tatal_list_count: 0
      },
      searchDate: {
        startDate: getDateStr(new Date(), 'yyyy-MM-dd'),
        endDate: getDateStr(new Date(), 'yyyy-MM-dd')
      },
      gameType: '',
      pageType: ''
    }
  },
  methods: {
    ApiFailError,
    changeLocale(lang) {
      if (this.$i18n.locale === lang) {
        return
      } else {
        this.$i18n.locale = lang
      }
      setLangCookie(lang)
    },
    isNumber: function(evt) {
      evt = (evt) || window.event
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode !== 8 && charCode !== 37 && charCode !== 39 && charCode !== 188 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    onFocus(e) {
      e.target.removeAttribute('readonly')
    },
    onAlert(level,code, message, type, callback) {
      if (!type) {
        type = 'check'
      }

      const component = isMobile() ? AlertMobile : Alert
      this.$vfm.show({
        component: component,
        bind: {
          code: code,
          msg: message,
          type: type,
          level: level
        },
        on: {
          confirm(result, close) {
            close()
            if (type === 'confirm') {
              if (callback) {
                callback(result)
              }
            }
          },
          check(result, close) {
            close()
            if (type === 'check') {
              if (callback) {
                callback(result)
              }
            }
          }
        }
      })
    },
    onConfirm(code, message) {
      return new Promise((resolve, reject) => {
        this.onAlert('agreeart',code, message, 'confirm', (result) => {
          if (result === 'yes') {
            resolve(true)
          } else {
            resolve(false)
          }
        })
      })
    },
    onCheck(code, message) {
      return new Promise((resolve, reject) => {
        this.onAlert('agreeart',code, message, 'check', (result) => {
          if (result === 'ok') {
            resolve(true)
          } else {
            resolve(false)
          }
        })
      })
    },
    onChangeDate(date) {
      this.searchDate.startDate = date.startDate
      this.searchDate.endDate = date.endDate
    },
    onAllCheck(list) {
      this.allChecked = !this.allChecked
      this[list].forEach(item => {
        item.checked = this.allChecked
      })
    },
    onCashListSearchMobile(page) {
      this.pageType = 'more'
      this.onCashListSearch(page)
    },
    onCashListSearch(page) {
      if (!page || page === true) page = this.pageInfo.page
      const params = {
        page: page,
        startDate: getDateStr(this.searchDate.startDate, 'yyyy-MM-dd 00:00:00'),
        endDate: getDateStr(this.searchDate.endDate, 'yyyy-MM-dd 23:59:59'),
        cashType: this.cashType
      }
      cashList(params).then(response => {
        const result = response.data
        // cashStatus :(0:신청 , 1:신청처리완료. 2:대기, -1 취소 )
        if (result.resultCode === '200') {
          const list = result.data.list
          if (result.data.pageInfo) {
            this.pageInfo = result.data.pageInfo
          }
          for (let i = 0, iLen = list.length; i < iLen; i++) {
            const item = list[i]
            item.checked = false
          }
          if (this.pageType === 'more') {
            this.cashList = this.cashList.concat(list)
          } else {
            this.cashList = list
          }
        } else {
          alert('error')
        }
      }).then(() => {

      }).catch(err => {
        console.error(err)
      })
    },
    computedCashStatus(cashStatus) {
      let returnData = CASH_STATUS[cashStatus]
      if (isMobile()) {
        returnData = CASH_STATUS_MOBILE[cashStatus]
      }
      if (returnData) {
        return returnData
      } else {
        return {
          class: '',
          text: ''
        }
      }
    },
    thousand(value) {
      return value ? thousand(value) : 0
    },
    dateFormat(value) {
      return value ? value.substring(0, value.length - 3) : ''
    },
    dateStrKorean(value, defaultStr, type) {
      if (!value) {
        value = new Date()
      }
      if (!defaultStr) {
        defaultStr = 'MM월 dd일'
      }

      const week = ['일', '월', '화', '수', '목', '금', '토']

      const dayOfWeek = week[value.getDay()]

      const str = `${defaultStr} (${dayOfWeek}) HH시`

      return format(value, str)
    },
    dateFormatAll(value) {
      if (value) {
        return value.replace('T', ' ')
      } else {
        return ''
      }
    },
    dateFormatForBoard(value) {
      return value ? value.substring(0, value.length - 3).replace('T', ' ').replace(/-/g, '.') : ''
    },
    dateFormatForMobile(value) {
      return value ? value.substring(0, value.length - 9).replace('T', ' ').replace(/-/g, '.') : ''
    },
    goPageByName(name, params) {
      const query = { name: name }
      if (params) {
        query.params = params
      }
      this.$router.push(query)
    },
    replacePageByName(name) {
      this.$router.replace({ name: name })
    },
    goBack() {
      this.$router.go(-1)
    },
    signOut() {
      signOut({}).then(response => {
        removeAuthData()
        resetRunMode()
        // alert(getRunMode())
        location.href = '/'
      })
    },
    onBettingList(page, code, pageType,groundCode, oldYn) {
      console.log(page, code, pageType, oldYn)
      // if (!page) page = this.pageInfo.page
      // if (!code) return false
      if (this.userData) {
        const params = {
          gameCategory: groundCode,
          vendorCode: code ? code.toString() : '',
          startDate: this.searchDate.startDate,
          endDate: this.searchDate.endDate,
          page: page,
          oldYn: oldYn
        }
        return bettingList(params)
          .then(response => {
            const result = response.data
            if (result.resultCode === '200') {
              const list = result.data.list
              if (result.data.pageInfo) {
                this.pageInfo = result.data.pageInfo
              } else {
                this.pageInfo = {
                  page: 1,
                  count_per_list: 10,
                  tatal_list_count: 0
                }
              }

              if (list.length > 0) {
                for (let i = 0, iLen = list.length; i < iLen; i++) {
                  const item = list[i]
                  item.resultCash = Number(item.winCash) - Number(item.cash)
                  if (item.type === 'win') {
                    item.betCalResults = 'PASS'
                  } else if (item.type === 'lose') {
                    item.betCalResults = 'FAIL'
                  } else if (item.type === 'draw') {
                    item.betCalResults = 'TIE'
                  } else if (item.type === 'wait') {
                    item.betCalResults = 'WAIT'
                  } else {
                    if(item.betStatus === 'WAIT') {
                      item.betCalResults = 'BET'
                    }else {
                      item.betCalResults = 'CANCEL'
                    }
                  }
                }
                if (pageType !== 'mobile') {
                  window.scrollTo(0, 0)
                }
                return list
              } else {
                return []
              }
            } else {
              return []
            }
          })
      }
    },
    async getBetttingDetail(betIdx) {
      const request = { betIdx: betIdx }
      try{
        const response = await bettingDetail(request)
        console.log(response)
      }catch(e) {
        console.log(e)
      }
    },
    async onSelectGame(groupCode, codeName) {
      this.emitter.emit('Loading', true)
      await tokenCheck(true)
      if (this.userData) {
        const codeInfo = this.commonCode[groupCode][codeName]
        const params = {
          vendorKey: codeInfo.code.toString(),
          gameType: codeInfo.groupCode.toString(),
          gameKey: ''
        }
        if (codeInfo.groupCode.toString() === 'slot') {
          this.emitter.emit('Loading', false)
          return params
        } else {
          this.onGetGame(params)
        }
      } else {
        this.emitter.emit('Loading', false)
        this.onCheck('front.error.afterSignin')
      }
    },
    async onSlotSelectGame(params) {
      this.emitter.emit('Loading', true)
      if (this.userData) {
        this.onGetGame(params)
      } else {
        this.emitter.emit('Loading', false)
        await this.onCheck('front.error.afterSignin')
      }
    },
    async onCasinoSelectGame(gameType, vendorKey) {
      this.emitter.emit('Loading', true)
      if (this.userData) {
        const params = {
          vendorKey: vendorKey,
          gameType: gameType,
          gameKey: ''
        }
        this.onGetGame(params, gameType)
      } else {
        this.emitter.emit('Loading', false)
        await this.onCheck('front.error.afterSignin')
      }
    },
    onGetGame(params, gameType) {
      this.emitter.emit('Loading', true)
      const vendorKey = params.vendorKey

      let isIframe = !NonIframeGameCode.includes(vendorKey)

      getGame(params).then(async response => {
        console.log(params)
        console.log(response)
        const result = response.data
        if (result.resultCode === '200') {
          const data = result.data
          const gameUrl = data.gameurl
          const _width = 1500
          const _height = 800
          const _left = Math.ceil((window.screen.width - _width) / 2)
          const _top = 50

          if (gameUrl.indexOf('https://') > -1) {
            isIframe = true
          } else {
            isIframe = false
          }

          const realGameUrl = isIframe ? '/slot/game?url=' + encodeURIComponent(gameUrl) : gameUrl
          const target = isIframe ? 'evolution' : '_blank'

          if (isMobile()) {
            if (window.gamePopupTest) {
              window.gamePopupTest.close()
            }

            window.gamePopupTest = window.open(realGameUrl, target)
          } else {
            if (window.gamePopupTest) {
              window.gamePopupTest.close()
            }
            if (gameType !== 'minigame') {
              window.gamePopupTest = window.open(realGameUrl, target, `width=${_width},height=${_height},left=${_left},top=${_top}`)
            } else {
              const height = 800
              const width = height / 1.78
              const left = Math.ceil((window.screen.width - width) / 2)
              window.gamePopupTest = window.open(realGameUrl, target, `width=${width},height=${height},left=${left},top=${_top}`)
            }
          }

          if (window.gamePopupTestInterval) {
            clearInterval(window.gamePopupTestInterval)
            await store.dispatch('storeUserCoinAmt')
          }
          console.log('iframe : ', isIframe)

          if (!isIframe) {
            window.gamePopupTestInterval = setInterval(async() => {
              console.log('window check')
              if (window.gamePopupTest.closed) {
                this.emitter.emit('Loading', false)
                clearInterval(window.gamePopupTestInterval)
                await store.dispatch('storeUserCoinAmt')
              }
              if (this.userData) {
                await tokenCheck()
              } else {
                this.emitter.emit('Loading', false)
                clearInterval(window.gamePopupTestInterval)
                window.gamePopupTest.close()
                await store.dispatch('storeUserCoinAmt')
              }
            }, 10000, this)
          }
          this.emitter.emit('Loading', false)
        } else {
          this.emitter.emit('Loading', false)
        }
      })
    },
    getBoardList(params, page) {
      if (!page) page = this.pageInfo.page
      params.page = page
      return getBoardList(params)
        .then(response => {
          const result = response.data

          if (result.resultCode === '200') {
            let idxList = []
            const list = result.data.list
            if (result.data.pageInfo) {
              this.pageInfo = result.data.pageInfo
            }
            if ((params.boardType === 'nt' || params.category === 'normal') && result.data.idxList) {
              idxList = result.data.idxList
              setBoardCookie(idxList)
            }
            return { list: list, idxList }
          }
        })
    },
    getBoardDetail(params) {
      return getBoardDetail(params)
        .then(response => {
          const result = response.data

          if (result.resultCode === '200') {
            const board = result.data.board
            return board
          }
        })
    },
    async setSaveBoard(params, action, callback) {
      params.memId = this.userData.memId
      params.memName = this.userData.memName

      let confirm
      if (action === 'delete') {
        confirm = await this.onConfirm('front.board.confirmDelete')
      } else {
        confirm = await this.onConfirm('front.board.confirmUpload')
      }

      if (confirm) {
        saveBoard(params)
          .then(async response => {
            const result = response.data

            if (result.resultCode === '200') {
              let check
              if (action === 'delete') {
                check = await this.onCheck('front.board.completeDelete')
              } else {
                check = await this.onCheck('front.board.complete')
              }

              if (check) {
                if (action === 'delete') {
                  callback()
                } else {
                  this.goBack()
                }
              }
            }
          })
      }
    },
    async setSaveReply(params) {
      params.regId = this.userData.memId
      params.memName = this.userData.memName

      const confirm = await this.onConfirm('front.board.confirmUpload')

      if (confirm) {
        return saveReply(params)
          .then(async response => {
            const result = response.data

            if (result.resultCode === '200') {
              const check = await this.onCheck('front.board.complete')
              return check
            }
          })
      }
    },
    async gameStarter(category, params = { vendorKey, gameKey, isMobile, lang }) {
      const browser = getBrowser()
      const msg = this.$t('front.error.afterSignin')
      if(!this.userData) {
        alert(msg)
        return
      }
      this.emitter.emit('Loading', true)

      try {
        switch(browser) {
          case 'Safari':
            await this.gameStarterSafari(category, params)
            break
          default:
            await this.gameStarterDefault(category, params)
        }
      } catch (e) {
        alert(e.message)
        console.log(e)
        // console.log(this.$router)
      } finally {
        this.emitter.emit('Loading', false)
      }
    },
    async gameStarterDefault(category, params) {
      try {
        const response = await fetchGameUrl(category, params)
        const { resultCode, resultMessage, data } = response.data
        if (resultCode === '200' && resultMessage === 'SUCCESS') {
          const browser = getBrowser()
          const phone = getPhone()
          const target = phone === 'iPhone' && browser === 'inApp' ? '_top' : '_blank'
          window.open(data.gameUrl, target, 'width=1280,height=720')
        } else {
          throw new Error(this.$t(`api.${resultCode}`))
        }
      }catch(e) {
        throw e
      }
    },
    async gameStarterSafari(category, params) {
      try {
        const gameWindow = window.open('/preload', '_blank', 'width=1280,height=720')
        let response = null
        response = await fetchGameUrl(category, params)
        const { resultCode, resultMessage, data } = response.data
        if (resultCode === '200' && resultMessage === 'SUCCESS') {
          gameWindow.location.href = data.gameUrl
          this.emitter.emit('Loading', false)
        } else {
          gameWindow.close()
          throw new Error(this.$t(`api.${resultCode}`))
        }
      }catch(e) {
        throw e
      }
    }
  }
}
